/* General Colors */
$gray1: #282828;
$gray2: #323232;
$gray3: #373737;
$gray4: #3c3c3c;

$primary1: #333;
$primary2: #333;
$primary3: #333;

$blue1: #287ad6;

$menuItemColor: #eaf2fa;

/* Text Colors */
$lightColor: #f5f8fa;
$darkColor: #182026;
$lightGrayColor: #bec0c5;
$darkGrayColor: #4c4c4c;
// $lightBlueColor: #bfccd6;
$lightBlueColor: rgba(41, 127, 202, 0.7);
$dirtyBlueColor: #5c7080;
$darkBlueColor: #297fca;
// E4E6EC

/* Background Colors */
$lightBackgroundColor: #fcfcfc;
$darkBackgroundColor: #1a1a1a;
$lightBarsBackgroundColor: white;

/* Border Colors */
$borderColor: rgba(16, 22, 26, 0.15);
$lightBorderColor: rgba(16, 22, 26, 0.1);
$darkBorderColor: rgba(138, 155, 168, 0.2);

/* Info Colors */
$redColor: #d04d47;

/* Error color */
$errorColor: red;
