/*@import './shared/styles/media.css';*/
@import './shared/styles/colors.scss';
@import './www/styles/scroll.scss';

body {
  font-family: 'Roboto';
}

:global {
  html,
  body {
    /* DSL: Regular - 16/20 */
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
  }

  body {
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    text-size-adjust: 100%;
    background-color: $lightBackgroundColor;

    &.bp3-dark {
      background-color: $darkBackgroundColor;
    }
  }

  body.noScroll {
    overflow: hidden;
  }

  strong {
    font-weight: 500;
  }

  pre {
    strong {
      font-weight: 600;
    }
  }

  input,
  input[type='search'] {
    -webkit-appearance: none;
  }

  input,
  select {
    &:active,
    &:focus {
      outline: none;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    line-height: initial;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  h1 {
    font-size: calc(100% * 2);
  }

  h2 {
    font-size: calc(100% * 1.8);
  }

  h3 {
    font-size: calc(100% * 1.6);
  }

  h4 {
    font-size: calc(100% * 1.4);
  }

  h5 {
    font-size: calc(100% * 1.2);
  }

  h6 {
    font-size: calc(100% * 1);
  }

  hr {
    border: 0;
    border-top: 1px #cdcfd2 solid;
  }

  /** {
    min-height: 0;
    min-width: 0;
  }*/

  .container-fluid,
  .container {
    max-width: 1200px;
  }

  .container-fluid {
    padding-left: .5rem;
    padding-right: .5rem;

    @media (--sm-viewport) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .container-narrow {
    max-width: 800px;
  }

  /* Automatically add a margin for col-xs-12 when collapsed */
  .col-xs-12 {
    @media (max-width: 479px) {
      margin-bottom: 1rem;

      &:last-child,
      &:only-child {
        margin-bottom: 0;
      }
    }
  }

  .first-xs {
    @media (max-width: 479px) {
      margin-bottom: 1rem !important;
    }
  }

  /* https://v4-alpha.getbootstrap.com/utilities/spacing/ */

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-1 {
    margin-bottom: 1rem;
  }

  .mb-2 {
    margin-bottom: 2rem;
  }

  .mb-3 {
    margin-bottom: 3rem;
  }

  .mt-0 {
    margin-top: 0;
  }

  .mt-1 {
    margin-top: 1rem;
  }

  .mt-2 {
    margin-top: 2rem;
  }

  .mt-3 {
    margin-top: 3rem;
  }

  .bp3-text-large {
    font-size: 20px;
    line-height: 1.4;
  }

  .bp3-running-text {
    font-size: 16px;
  }

  .text-xs-left {
    text-align: left;
  }

  .text-sm-left {
    @media (--sm-viewport) {
      text-align: left;
    }
  }

  .text-md-left {
    @media (--md-viewport) {
      text-align: left;
    }
  }

  .text-lg-left {
    @media (--lg-viewport) {
      text-align: left;
    }
  }

  .text-xs-center {
    text-align: center;
  }

  .text-sm-center {
    @media (--sm-viewport) {
      text-align: center;
    }
  }

  .text-md-center {
    @media (--md-viewport) {
      text-align: center;
    }
  }

  .text-lg-center {
    @media (--lg-viewport) {
      text-align: center;
    }
  }

  .text-xs-right {
    text-align: right;
  }

  .text-sm-right {
    @media (--sm-viewport) {
      text-align: right;
    }
  }

  .text-md-right {
    @media (--md-viewport) {
      text-align: right;
    }
  }

  .text-lg-right {
    @media (--lg-viewport) {
      text-align: right;
    }
  }

  /* Animations */

  .fade-appear {
    opacity: 0.01;
  }

  .fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 0.3s ease-in;
  }
}

ul[role='listbox'] {
  max-height: 200px;
  overflow-y: auto;
}

div.bp3-label > p {
  margin-bottom: 5px;
}

.bp3-form-group {
  &.bp3-intent-danger {
    .bp3-input.bp3-tag-input.bp3-multi-select,
    input {
      border: 1px solid red;
      box-shadow: none;
    }

    .bp3-input.bp3-tag-input.bp3-multi-select input {
      border: none;
    }
  }
}

.red-border {
  border: 1px solid red;
}
.top-15 {
  margin-top: 15px;
}
.br-5 {
  border-radius: 5px;
}
.required-hack {
  color: #c23030;
  font-size: 12px;
}
.p-l-b-5 {
  padding-bottom: 5px;
  padding-left: 5px;
}
.p-l-t-b-5 {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
}

.circular-checkbox {
  font-size: 0.875rem;
  color: $lightGrayColor;
  &.active {
    color: $darkBlueColor;
  }
  &:last-child {
    margin-bottom: 0px;
  }
  & > span {
    &.bp3-control-indicator.circular-indicator {
      border-radius: 50%;
      width: 0.75rem;
      height: 0.75rem;
      &::before {
        width: inherit;
        height: inherit;
      }
    }
  }
}

.bp3-slider {
  width: 85%;
  height: 15px;
  margin-top: 20px;
  margin-left: 10px;
  .bp3-slider-track {
    margin-top: 3px;
    .bp3-slider-progress {
      height: 1px;
    }
  }
  .bp3-slider-label {
    transform: translate(-50%, -12px);
    color: $darkBlueColor;
    background: transparent;
    // background: $lightBarsBackgroundColor;
  }
  .bp3-slider-handle {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: $darkBlueColor;
    background-image: none;
    box-shadow: none;
    margin-top: 3px;
    .bp3-slider-label {
      transform: translate(-50%, -20px);
      margin-left: 6px;
      box-shadow: none;
    }
  }
  .bp3-slider-axis {
    // .bp3-slider-label:first-child {
    //   transform: translate(-80%, -12px);
    // }
    // .bp3-slider-label:last-child {
    //   transform: translate(3%, -12px);
    // }
  }
}

.text-ellipsed {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
