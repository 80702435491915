@import '../shared/styles/colors.scss';

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 320px;
}

.main {
  padding-top: 60px;
  position: relative;
  flex: 1 0 auto;

  :global(.bp3-dark) & {
    background-color: $darkBackgroundColor;
  }
}

.wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
