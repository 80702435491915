@import '../../shared/styles/colors.scss';

*::-webkit-scrollbar {
  width: 0.6em;
  height: 0.4em;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}
*::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: $borderColor;
}
